import axios from "axios";
import { BASE_SANITY_GRAPHQL_URL } from "../constants/app";

export const fetchVideoLinks = async () => {
  const headers = {
    Authorization: "Bearer " + process.env.REACT_APP_SANITY_API_TOKEN,
  };
  const result = await axios({
    url: BASE_SANITY_GRAPHQL_URL,
    method: "post",
    data: {
      query: `
        query getAllVideoLinks {
          allVideolink {
            _id,
            name,
            link,
            featuredvideo
          }
        }
      `,
    },
    headers,
  });
  return result;
};

export const fetchFeaturedVideoLinks = async () => {
  const headers = {
    Authorization: "Bearer " + process.env.REACT_APP_SANITY_API_TOKEN,
  };
  const result = await axios({
    url: BASE_SANITY_GRAPHQL_URL,
    method: "post",
    data: {
      query: `
        query getAllVideoLinks {
          allVideolink(where: {featuredvideo: {eq: true}}) {
            _id,
            name,
            link,
            featuredvideo
          }
        }
      `,
    },
    headers,
  });
  return result;
};
