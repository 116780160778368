import EpkVideos from "../epk/EpkVideos";
import Press from "../epk/Press";
import EpkBio from "../epk/EpkBio";
import Contact from "../epk/Contact";
import Music from "../epk/Music";
import Downloads from "../epk/Downloads";

function EpkPage() {
  return (
    <div className="home-page">
      <EpkBio />
      <Music />
      <EpkVideos />
      <Press />
      <Downloads />
      <Contact />
    </div>
  );
}

export default EpkPage;
