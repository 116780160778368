import { useEffect, useState } from "react";
import { fetchFeaturedVideoLinks } from "../../services/videolinks";

function FeaturedVideos() {
  const [videoLink, setVideoLink] = useState("");

  useEffect(() => {
    fetchFeaturedVideoLinks().then((result) => {
      setVideoLink(result.data.data.allVideolink[0].link);
    });
  }, []);

  return (
    <div className="video-section" id="videos">
      <div className="container" style={{ padding: "50px" }}>
        <div className="row justify-content-md-center">
          <div className="col-md-12">
            <h2
              style={{
                textAlign: "center",
                fontFamily: "Barlow Semi Condensed",
                fontSize: "50px",
                marginBottom: "50px",
              }}
            >
              Featured Videos
            </h2>
          </div>
          <div className="col-md-10 mb-5"></div>
          <div className="col-md-10">
            <iframe
              style={{ border: "0" }}
              width="100%"
              height="400"
              src={videoLink}
              title="YouTube video player"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedVideos;
