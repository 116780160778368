import { useEffect, useState } from "react";
import { fetchAssetsByCategory } from "../../services/asset";

function EpkBio() {
  const [bioImg, setBioImg] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAssetsByCategory("bio").then((result) => {
      const firstBioImgReturned = result.data.data.allAsset[0];
      if (
        firstBioImgReturned.file.asset.extension === "jpg" ||
        firstBioImgReturned.file.asset.extension === "png"
      ) {
        setBioImg(firstBioImgReturned.file.asset.url);
      }
      setLoading(false);
    });
  }, []);

  return (
    <div className="container" id="epk-bio">
      <div className="row">
        <div className="col-md-12">
          <h2
            style={{ textAlign: "center", fontSize: "50px", marginTop: "50px" }}
          >
            Bio
          </h2>
        </div>
        <div className="col-md-6 m-auto">
          {!loading && (
            <img
              alt="The Boneheads performing at Rock the Colosseum in Mississauga"
              src={bioImg}
              className="epk-band-img"
            />
          )}
        </div>
        <div className="col-md-6 m-auto">
          <p>
            The Boneheads have kept true to the roots while emerging from the Toronto music scene. The award-winning band brings a refreshment of blues-tinged rock 'n' roll, combining old school and modern influences with the likes of Howlin' Wolf, Creedence Clearwater Revival, and The Black Keys.
          </p>
          <p>
            They have built up their local reputation quickly as winners of Music Group (Emerging) at the 2024 Mississauga Arts Awards and Best New Group at the 2023 Mississauga Music Awards. The group has seen success outside their hometown as well, landing many festival performances and opening for major Canadian rock artists The Glorious Sons and JJ Wilde.
          </p>
          <p>
            Their latest single Ride made its radio debut on 94.9 The Rock FM as part of the Generation Next segment. Also featured on The Music Jerks radio show, the song was described as “a huge, stadium-worthy sound and stellar melodies. While the style is commercially viable, it's still gritty and edgy."
          </p>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default EpkBio;
