import axios from "axios";
import { BASE_SANITY_GRAPHQL_URL } from "../constants/app";

export const fetchPress = async () => {
  const headers = {
    Authorization: "Bearer " + process.env.REACT_APP_SANITY_API_TOKEN,
  };
  const result = await axios({
    url: BASE_SANITY_GRAPHQL_URL,
    method: "post",
    data: {
      query: `
        query getAllPress {
          allPress {
            _id,
            title,
            description,
            link,
            category
          }
        }
      `,
    },
    headers,
  });
  return result;
};
