// import { useEffect, useState } from "react";
// import EventCard from "./EventCard";
// import { fetchTours } from "../../services/tour";

function TourDates() {
  return (
    <div className="tour-dates" id="tours">
      <div className="container" style={{ padding: "50px" }}>
        <div className="row">
          <div className="col-12">
            <h2
              style={{
                textAlign: "center",
                fontFamily: "Barlow Semi Condensed",
                fontSize: "50px",
              }}
            >
              Tour Dates
            </h2>
            <a
              key={Math.random()}
              href="https://www.songkick.com/artists/10175143"
              className="songkick-widget"
              data-theme="dark"
              data-track-button="off"
              data-detect-style="off"
              data-background-color="rgb(255,255,255,1)"
              data-font-color="rgb(0,0,0,1)"
              data-button-bg-color="rgb(255,255,255,1)"
              data-button-text-color="rgb(255,255,255,1)"
              data-locale="en"
              data-other-artists="on"
              data-share-button="on"
              data-country-filter="on"
              data-rsvp="off"
              data-request-show="off"
              data-past-events="on"
              data-past-events-offtour="off"
              data-remind-me="on"
            >
              Events
            </a>
            {/* <div className="events-listing">
              <ol>
                {dateFilteredEvents.length > 0 ? (
                  <>
                    {dateFilteredEvents.map((event, i) => {
                      return (
                        <EventCard
                          key={i}
                          link={event.link}
                          city={event.city}
                          venue={event.venue}
                          date={event.date}
                          price={event.price}
                        />
                      );
                    })}
                  </>
                ) : (
                  <p style={{ textAlign: "center" }}>No upcoming shows</p>
                )}
              </ol>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TourDates;
