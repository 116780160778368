import SocialLinks from "../SocialLinks";

function SocialBanner() {
  return (
    <div className="social-banner">
      <hr style={{ marginLeft: "5%", marginRight: "5%" }} />
      <div className="container" id="social" style={{ padding: "50px" }}>
        <div className="row justify-content-md-center">
          <div
            className="col-md-12"
            style={{ margin: "0px auto 50px auto", width: "100%" }}
          >
            <h2
              style={{
                textAlign: "center",
                fontFamily: "Barlow Semi Condensed",
                fontSize: "50px",
              }}
            >
              Follow Us
            </h2>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div
            className="col-md-6 justify-content-center text-center"
            style={{ justifyContent: "center" }}
          >
            <SocialLinks />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialBanner;
