// import banner from "../../assets/images/MT_00262.jpg";
import videoBanner from "../../assets/videos/Boneheads-Horseshoe-Wide.mp4";

function Banner() {
  return (
    <div className="banner-photo">
      <div
        className="row justify-content-md-center"
        style={{ padding: "0", margin: "0", width: "100%" }}
      >
        <div
          className="md-auto"
          style={{ padding: "0", margin: "0", width: "100%" }}
        >
          {/* <img
              alt="The band members of the boneheads"
              src={banner}
              style={{
                width: "100vw",
                maxHeight: "700px",
                objectFit: "cover",
                objectPosition: "100% 30%",
              }}
            /> */}
          <video
            src={videoBanner}
            id="banner-video"
            autoPlay
            muted
            loop
            playsInline
            disablePictureInPicture
            style={{ objectFit: "cover", width: "100%", height: "85vh" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Banner;
