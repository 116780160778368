import { useEffect, useState } from "react";
import { fetchAssetsByCategory } from "../../services/asset";
import { formatBytes } from "../../utils/stringUtils";

function Downloads() {
  const [pressPhotos, setPressPhotos] = useState([]);
  const [downloadLinks, setDownloadLinks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAssetsByCategory("press-photo").then((result) => {
      const returnedAssets = result.data.data.allAsset;
      const validPressPhotos = [];
      returnedAssets.forEach((asset) => {
        if (
          asset.file.asset.extension === "jpg" ||
          asset.file.asset.extension === "png"
        ) {
          validPressPhotos.push(asset);
        }
      });
      setPressPhotos(validPressPhotos);
      setLoading(false);
    });
    fetchAssetsByCategory("download-link").then((result) => {
      setDownloadLinks(result.data.data.allAsset);
    });
  }, []);

  return (
    <div className="container" id="downloads">
      <div className="row">
        <div className="col-md-12">
          <h2
            style={{ textAlign: "left", fontSize: "50px", marginTop: "50px" }}
          >
            Press Photos
          </h2>
        </div>
      </div>
      {!loading && (
        <div className="row">
          {pressPhotos.map((photo, i) => {
            return (
              <div key={i} className="col-md-4">
                <img
                  alt="Headshot of the band"
                  src={photo.file.asset.url}
                  style={{
                    width: "100%",
                    margin: "5px",
                    height: "230px",
                    objectFit: "cover",
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
      <div className="row">
        <div className="col-md-12">
          <h2
            style={{ textAlign: "left", fontSize: "50px", marginTop: "50px" }}
          >
            Downloadable Links
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="downloadable-files">
            {downloadLinks.map((link, i) => {
              return (
                <a key={i} href={link.file.asset.url} download>
                  <p>
                    {link.name} | {formatBytes(link.file.asset.size)}
                  </p>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Downloads;
