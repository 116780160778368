import axios from "axios";
import { BASE_SANITY_GRAPHQL_URL } from "../constants/app";

export const fetchAssets = async () => {
  const headers = {
    Authorization: "Bearer " + process.env.REACT_APP_SANITY_API_TOKEN,
  };
  const result = await axios({
    url: BASE_SANITY_GRAPHQL_URL,
    method: "post",
    data: {
      query: `
        query getAllAssets {
          allAsset {
            _id,
            name,
            category,
            file {
              asset {
                url,
                size,
                extension
              }
            }
          }
        }
      `,
    },
    headers,
  });
  return result;
};

export const fetchAssetsByCategory = async (category) => {
  const headers = {
    Authorization: "Bearer " + process.env.REACT_APP_SANITY_API_TOKEN,
  };
  const result = await axios({
    url: BASE_SANITY_GRAPHQL_URL,
    method: "post",
    data: {
      query: `
        query getAllAssetsByCategory {
          allAsset(where: {category: {eq: "${category}"}}) {
            _id,
            name,
            category,
            file {
              asset {
                url,
                size,
                extension
              }
            }
          }
        }
      `,
    },
    headers,
  });
  return result;
};
