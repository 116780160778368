import SubscribeForm from "./SubscribeForm";

function Footer() {
  return (
    <footer>
      <div
        className="container"
        style={{
          backgroundColor: "#444444",
          minHeight: "25vh",
          minWidth: "100%",
        }}
      >
        <div className="row justify-content-md-center">
          <SubscribeForm />
          <div className="col-md-12">
            <p
              style={{ color: "white", marginTop: "5vh", textAlign: "center" }}
            >
              &copy;2023 The Boneheads&nbsp;|&nbsp;
              <a
                href={"mailto:" + process.env.REACT_APP_BONEHEADS_EMAIL}
                style={{ color: "white", textDecoration: "none" }}
              >
                Contact Us
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
