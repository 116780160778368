function SubscribeForm() {
  // TODO: Waiting on emailjs to change this method
  function sendEmail() {
    // const from_email = document.getElementById("email").value;
    // window.Email.send({
    //   SecureToken: "0b8252ae-8d89-4ee3-8e81-2d9179c4fd84",
    //   To: process.env.REACT_APP_BONEHEADS_EMAIL,
    //   From: from_email,
    //   Subject: "Subscribe email",
    //   Body: from_email + " would like to subscribe"
    // }).then(
    //   message => alert(message)
    // );
    alert("Subscription service is currently under construction");
  }

  return (
    <div
      className="col-md-12"
      style={{ textAlign: "center", marginTop: "20px" }}
    >
      <input
        type="text"
        id="email"
        name="email"
        placeholder="Get The Boneheads Updates"
        style={{ minWidth: "25vw", backgroundColor: "#444444", color: "white" }}
      />
      <input
        type="submit"
        name="submit"
        value="Subscribe"
        style={{ backgroundColor: "#B7D498", color: "white" }}
        onClick={sendEmail}
      />
      <input type="text" name="hp" id="hp" />
    </div>
  );
}

export default SubscribeForm;
