import { useEffect, useState } from "react";
import { fetchPress } from "../../services/press";
import { newLineText } from "../../utils/stringUtils";

function Press() {
  const [press, setPress] = useState([]);

  useEffect(() => {
    fetchPress().then((result) => {
      setPress(result.data.data.allPress);
    });
  }, []);

  return (
    <div id="press">
      <div className="container" style={{ padding: "50px" }}>
        <div className="row">
          <div className="col-md-12">
            <h2
              style={{
                textAlign: "center",
                fontSize: "50px",
                marginBottom: "40px",
              }}
            >
              Press
            </h2>
          </div>
        </div>
        <div className="row">
          {press.map((item, i) => {
            return (
              <div key={i} className="col-md-6">
                <div className="press-cards">
                  <div className="press-card">
                    <h4>{item.title}</h4>
                    <div>{newLineText(item.description)}</div>
                    <a target="_blank" rel="noreferrer" href={item.link}>
                      {item.category === "listen" ? (
                        <button key={i} className="btn btn-light">
                          <i className="fab fa-spotify"></i>Listen
                        </button>
                      ) : (
                        <button className="btn btn-light">Read Article</button>
                      )}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Press;
