import { useEffect, useState } from "react";
import { fetchAssetsByCategory } from "../../services/asset";

function FeaturedAlbum() {
  const [featuredAlbum, setFeaturedAlbum] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAssetsByCategory("featured-album").then((result) => {
      const firstFeaturedAlbumImgReturned = result.data.data.allAsset[0];
      if (
        firstFeaturedAlbumImgReturned.file.asset.extension === "jpg" ||
        firstFeaturedAlbumImgReturned.file.asset.extension === "png"
      ) {
        setFeaturedAlbum(firstFeaturedAlbumImgReturned.file.asset.url);
      }
      setLoading(false);
    });
  }, []);

  return (
    <div className="featured-album-section" id="music">
      <div className="container" style={{ marginBottom: "50px" }}>
        <div className="row" style={{ display: "flex" }}>
          <div className="col-md-6" style={{ margin: "65px 0px 30px 0px" }}>
            {!loading && (
              <img
                alt="Boneheads featured album cover"
                src={featuredAlbum}
                style={{ height: "430px", width: "90%" }}
                id="album-art"
              />
            )}
          </div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <h2
              className="out-now"
              style={{
                fontFamily: "Barlow Semi Condensed",
                fontSize: "50px",
                paddingTop: "150px",
              }}
            >
              FEEL THE LIGHT
            </h2>
            <h2
              className="out-now"
              style={{ fontFamily: "Barlow Semi Condensed", fontSize: "50px" }}
            >
              OUT NOW
            </h2>
            <p style={{ textAlign: "center", paddingTop: "20px" }}></p>
            <i
              className="fab fa-spotify"
              style={{ fontSize: "2em", padding: "10px" }}
            ></i>
            <i
              className="fab fa-apple"
              style={{ fontSize: "2em", padding: "10px" }}
            ></i>
            <i
              className="fab fa-google-play"
              style={{ fontSize: "2em", padding: "10px" }}
            ></i>
            <i
              className="fab fa-amazon"
              style={{ fontSize: "2em", padding: "10px" }}
            ></i>
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/track/6rRrH4ZPEOtODBZnJGLaWU?si=c5ade7a50ade4070"
              >
                <button className="btn btn-dark">Listen Now</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedAlbum;
