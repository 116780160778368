export const newLineText = (text) => {
  return text.split("\\n").map((str, i) => (
    <p key={i} className="newline-text">
      {str}
    </p>
  ));
};

export const formatBytes = (bytes, decimals = 1) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
