import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function NavigationDropdown({
  mainLink,
  dropdownLinks,
  mainText,
  dropdownTexts,
}) {
  return (
    <div className="dropdown">
      <p>
        <Link to={mainLink} className="page-section-link">
          {mainText}
          <span style={{ fontSize: "12px", marginBottom: "25px" }}>
            &#x25BC;
          </span>
        </Link>
      </p>
      <div className="dropdown-content">
        {dropdownTexts &&
          dropdownTexts.map((element, index) => {
            return (
              <div key={index}>
                <p>
                  <HashLink
                    to={mainLink + "#" + dropdownLinks[index]}
                    className="page-section-link"
                  >
                    {dropdownTexts[index]}
                  </HashLink>
                </p>
                {index !== dropdownTexts.length - 1 && <hr />}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default NavigationDropdown;
