import Banner from "../main/Banner";
import FeaturedAlbum from "../main/FeaturedAlbum";
import SocialBanner from "../main/SocialBanner";
import TourDates from "../main/TourDates";
import FeaturedVideos from "../main/FeaturedVideos";
// import Carousel from "../main/Carousel";
// import NewMusic from "../main/NewMusic";

function HomePage() {
  return (
    <div className="home-page">
      <Banner />
      {/* <NewMusic /> */}
      <FeaturedAlbum />
      <FeaturedVideos />
      <TourDates />
      {/* <Carousel /> */}
      <SocialBanner />
    </div>
  );
}

export default HomePage;
