import { useEffect, useState } from "react";
import { fetchVideoLinks } from "../../services/videolinks";

function EpkVideos() {
  const [videoLinks, setVideoLinks] = useState([]);

  useEffect(() => {
    fetchVideoLinks().then((result) => {
      setVideoLinks(result.data.data.allVideolink);
    });
  }, []);

  return (
    <div className="video-section" id="videos">
      <div className="container" style={{ padding: "50px" }}>
        <div className="row justify-content-md-center">
          <div className="col-md-12">
            <h2
              style={{
                textAlign: "center",
                fontFamily: "Barlow Semi Condensed",
                fontSize: "50px",
                marginBottom: "50px",
              }}
            >
              Videos
            </h2>
          </div>
          {videoLinks.map((videoLink, i) => {
            return (
              <div key={i} className="col-md-10 mb-5">
                <iframe
                  style={{ border: "0" }}
                  width="100%"
                  height="400"
                  src={videoLink.link}
                  title="YouTube video player"
                  allowFullScreen
                ></iframe>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default EpkVideos;
