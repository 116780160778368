function Music() {
  return (
    <div className="container" id="music">
      <div className="row" style={{ display: "flex" }}>
        <div className="col-md-12">
          <h2
            style={{ textAlign: "center", fontSize: "50px", marginTop: "50px" }}
          >
            Music
          </h2>
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-md-6">
          <iframe
            title="featured-tracks-spotify"
            style={{
              borderRadius: "12px",
              margin: "50px 0px 80px 0px",
              border: "0",
            }}
            src="https://open.spotify.com/embed/artist/7zW3zPvRMV1ZGj3UYkImSZ?utm_source=generator&theme=0"
            width="100%"
            height="380"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Music;
