import SocialLinks from "../SocialLinks";

function Contact() {
  return (
    <div className="container" id="contact">
      <hr />
      <div className="row justify-content-md-center">
        <div className="col-md-12 m-auto">
          <h2
            style={{
              textAlign: "center",
              fontFamily: "Barlow Semi Condensed",
              fontSize: "50px",
            }}
          >
            Contact
          </h2>
        </div>
        <div className="col-md-12 m-auto">
          <p style={{ textAlign: "center" }}>
            Contact us at {process.env.REACT_APP_BONEHEADS_EMAIL} or at one of
            our socials below
          </p>
        </div>
        <div
          className="col-md-6 justify-content-center text-center"
          style={{ justifyContent: "center" }}
        >
          <SocialLinks />
        </div>
      </div>
    </div>
  );
}

export default Contact;
