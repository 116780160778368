function SocialLinks() {
  return (
    <div style={{ width: "100%" }}>
      <a
        href="https://www.facebook.com/theboneheadsmusic"
        className="social-link"
        id="facebook-link"
      >
        <i
          className="fab fa-facebook-square"
          style={{ fontSize: "2em", marginTop: "10px" }}
        ></i>
      </a>
      <a
        href="https://www.instagram.com/theboneheads/"
        className="social-link"
        id="instagram-link"
      >
        <i
          className="fab fa-instagram"
          style={{ fontSize: "2em", marginTop: "10px" }}
        ></i>
      </a>
      <a
        href="https://www.twitter.com/theboneheadsto/"
        className="social-link"
        id="twitter-link"
      >
        <i
          className="fab fa-twitter"
          style={{ fontSize: "2em", marginTop: "10px" }}
        ></i>
      </a>
      <a
        href="https://www.tiktok.com/@theboneheadsmusic"
        className="social-link"
        id="tiktok-link"
      >
        <i
          className="fab fa-tiktok"
          style={{ fontSize: "2em", marginTop: "10px" }}
        ></i>
      </a>
      <a
        href="https://www.youtube.com/@theboneheadsmusic"
        className="social-link"
        id="youtube-link"
      >
        <i
          className="fab fa-youtube"
          style={{ fontSize: "2em", marginTop: "10px" }}
        ></i>
      </a>
    </div>
  );
}

export default SocialLinks;
