import logo from "../assets/images/the_boneheads_stacked_20220117223657.png";
import NavigationDropdown from "./NavigationDropdown";
import { HashLink } from "react-router-hash-link";

function NavigationBar() {
  return (
    <div className="navigation-bar">
      <div
        className="container sticky-top"
        style={{ backgroundColor: "white" }}
      >
        <div className="row" id="navigation-bar-items">
          <div
            className="col-md-4 justify-content-center"
            id="navigation-bar-links"
            style={{ margin: "auto 0px", display: "flex" }}
          >
            <p>
              <HashLink to="/#music" className="page-section-link">
                Music
              </HashLink>
            </p>
            <p>
              <HashLink to="/#videos" className="page-section-link">
                Videos
              </HashLink>
            </p>
            <p>
              <HashLink to="/#tours" className="page-section-link">
                Tour
              </HashLink>
            </p>
            <NavigationDropdown
              mainLink={"epk"}
              mainText={"EPK"}
              dropdownTexts={["Music", "Videos", "Press", "Contact"]}
              dropdownLinks={["music", "videos", "press", "contact"]}
            />
            <p>
              <HashLink to="/#social" className="page-section-link">
                Social
              </HashLink>
            </p>
          </div>
          <div
            className="col-md-4 row align-items-center justify-content-center"
            style={{ marginLeft: "15px" }}
          >
            <a href="/">
              <img
                alt="boneheads logo"
                src={logo}
                style={{
                  height: "75px",
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavigationBar;
